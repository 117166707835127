<template>
  <b-card
    title="Linha do tempo"
  >
    <app-timeline>
      <app-timeline-item
        title="Abertura"
        :subtitle="timeLine.dataAbertura"
        icon="AwardIcon"
        time=""
        variant="success"
      />

      <app-timeline-item
        v-if="timeLine.agendamento"
        title="Em Atendimento"
        :subtitle="timeLine.dataAgendamento"
        icon="InfoIcon"
        time=""
        variant="info"
      />

      <app-timeline-item
        v-if="timeLine.aguardandoAprovacao"
        title="Aguardando Aprovação"
        :subtitle="timeLine.dataAguardandoAprovacao"
        icon="ClockIcon"
        time=""
        variant="warning"
      />

      <app-timeline-item
        v-if="timeLine.emReparo"
        title="Em Reparo"
        :subtitle="timeLine.dataEmReparo"
        icon="UserIcon"
        time=""
        variant="success"
      />

      <app-timeline-item
        v-if="timeLine.reparoConcluido"
        title="Reparo Concluído"
        :subtitle="timeLine.dataReparoConcluido"
        icon="GridIcon"
        time=""
        variant="success"
      />

      <app-timeline-item
        v-if="timeLine.reparoEntregue"
        title="Reparo Entregue"
        :subtitle="timeLine.dataReparoEntregue"
        icon="GridIcon"
        time=""
        variant="success"
      />

      <app-timeline-item
        v-if="timeLine.cancelado"
        title="Cancelado"
        :subtitle="timeLine.dataCancelado"
        icon="GridIcon"
        time=""
        variant="danger"
      />

      <app-timeline-item
        v-if="timeLine.negado"
        title="Negado"
        :subtitle="timeLine.dataNegado"
        icon="GridIcon"
        time=""
        variant="danger"
      />

      <app-timeline-item
        v-if="timeLine.troca"
        title="Trocado"
        :subtitle="timeLine.dataTroca"
        icon="GridIcon"
        time=""
        variant="info"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,
    AppTimeline,
    // eslint-disable-next-line vue/no-unused-components
    AppTimelineItem,
  },
  data() {
    return {
      timeLine: {
        dataAbertura: null,
        agendamento: false,
        dataAgendamento: null,
        aguardandoAprovacao: false,
        dataAguardandoAprovacao: null,
        emReparo: false,
        dataEmReparo: null,
        reparoConcluido: false,
        dataReparoConcluido: null,
        reparoEntregue: false,
        dataReparoEntregue: false,
        troca: false,
        dataTroca: null,
        negado: false,
        dataNegado: null,
        cancelado: false,
        dataCancelado: null,
        ok: null,
      },
    }
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    // eslint-disable-next-line no-unused-vars
    const { timeLine, error } = await this.$store.dispatch('os/getTimeLine', { id: this.$route.params.id })
    if (error) {
      // Handler error
    } else if (timeLine) {
      Object.keys(timeLine).forEach(key => {
        this.timeLine[key] = timeLine[key]
      })
    }
  },
}
</script>
