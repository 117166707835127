<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          :items="items"
          :fields="fields"
        >
          <template #cell(created_at)="data">
            {{ dataHora(data.item.created_at) }}
          </template>

          <template #cell(valor_total_orcamento)="data">
            R$ {{ valorBr(data.item.valor_total_orcamento) }}
          </template>

          <template #cell(valor_total_aprovado)="data">
            R$ {{ data.item.valor_total_aprovado ? valorBr(data.item.valor_total_aprovado): '0,00' }}
          </template>

          <template #cell(status)="data">
            <b-badge :variant="resolveBadgeVariantStatusOrcamento(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #cell(acoes)="data">
            <b-row>
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top="'Visualizar Orçamento'"
                  class="color-icon cursor-pointer"
                  size="16"
                  icon="EyeIcon"
                  @click="openModalInfoOrcamento(data.item)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal
      ref="modalInfoOrcamento"
      :title="`Orcamento ${orcamentoSelected ? orcamentoSelected.id : ''}`"
      size="xl"
    >
      <viewOrcamentoModal v-if="orcamentoSelected" />

      <template #modal-footer="{}">
        <b-button
          v-show="orcamentoSelected.status === 'ENVIADO PARA SEGURADORA'"
          class="mr-1"
          size="md"
          variant="outline-success"
          @click="aprovarOrcamento(orcamentoSelected)"
        >
          Aprovar
          <feather-icon
            class="color-icon"
            size="16"
            icon="ThumbsUpIcon"
          />
        </b-button>

        <b-button
          v-show="orcamentoSelected.status === 'ENVIADO PARA SEGURADORA'"
          class="mr-1"
          size="md"
          variant="outline-danger"
          @click="negarOrcamento(orcamentoSelected)"
        >
          Negar
          <feather-icon
            class="color-icon"
            size="16"
            icon="ThumbsDownIcon"
          />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BTable, VBTooltip, BBadge, BModal, BButton } from 'bootstrap-vue'
import { mapState } from 'vuex'
import viewOrcamentoModal from '../../viewOrcamentoModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BModal,
    viewOrcamentoModal,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: 'acoes', label: 'AÇÕES' },
        { key: 'status', label: 'Status' },
        { key: 'tipo_orcamento', label: 'TIPO ORÇAMENTO' },
        { key: 'created_at', label: 'DATA' },
        { key: 'prestador_servico.nome', label: 'TECNICO/PRESTADOR' },
        { key: 'valor_total_orcamento', label: 'Valor' },
        { key: 'valor_total_aprovado', label: 'Valor Aprovado' },
      ],
      load: false,
    }
  },
  computed: {
    ...mapState({
      items: state => state.os.orcamentosOs,
      orcamentoSelected: state => state.os.orcamentoSelected,
    }),
  },
  methods: {
    openModalInfoOrcamento(item) {
      this.$store.commit('os/SET_ORCAMENTO_SELECTED', item)
      this.$refs.modalInfoOrcamento.show()
    },

    async aprovarOrcamento(item) {
      const body = {
        orcamento_id: item.id,
        status_retorno: 'APROVADO',
        valor_total_seguradora: 600,
      }

      const { error } = await this.$store.dispatch('os/aprovarOrcamento', body)

      // handle error
      if (error) {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro:',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })

        return null
      }

      await this.$store.dispatch('os/getOrcamentosOs', { id: item.id })

      this.$swal({
        title: 'SUCESSO!',
        icon: 'success',
        text: 'RETORNO CONFIRMADO!',
        timer: 1500,
        showConfirmButton: false,
      })

      return null
    },

    async negarOrcamento(item) {
      const body = {
        orcamento_id: item.id,
        status_retorno: 'NEGADOR',
      }

      const { error } = await this.$store.dispatch('os/negarOrcamento', body)

      // handle error
      if (error) {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro:',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })

        return null
      }

      await this.$store.dispatch('os/getOrcamentosOs', { id: item.id })

      this.$swal({
        title: 'SUCESSO!',
        icon: 'success',
        text: 'RETORNO CONFIRMADO!',
        timer: 1500,
        showConfirmButton: false,
      })

      return null
    },
  },
}
</script>
