<template>
  <div>
    <b-row>
      <b-col
        md="12"
        class="my-1"
      >
        <b-button
          class="float-right"
          variant="outline-primary"
          size="sm"
          @click="openModalNewHistorico"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-50"
          />
          <span class="align-middle">Nova Reclamação</span>
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="loadingHistorico">
      <app-timeline v-if="historico.eventos">
        <app-timeline-item
          v-for="item in historico.eventos"
          :key="item.id"
          :variant="varianteEvento(item.usuario_empresa.funcao.nome)"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ item.motivo_evento.nome }}</h6>
            <small class="text-muted">{{ item.created_at ? dataHora(item.created_at) : '' }}</small>
          </div>

          <div class="demo-spacing-0">
            <b-alert
              :variant="varianteEvento(item.usuario_empresa.funcao.nome)"
              show
            >
              <div class="alert-body">
                <span>{{ item.descricao }}</span>
              </div>
            </b-alert>
          </div>
        </app-timeline-item>
      </app-timeline>
    </b-overlay>

    <b-modal
      id="modal-historico"
      ref="modalHistorico"
      cancel-variant="outline-secondary"
      ok-title="Salvar"
      no-close-on-backdrop
      cancel-title="Cancelar"
      title="Novo Histórico"
      @ok="newHistorico"
    >
      <b-row>
        <b-col>
          <label>Motivo: </label>
          <v-select
            v-model="motivoSelected"
            :options="motivoEventos"
            label="nome"
            disabled
          />

          <label class="d-inline">Descrição: </label>
          <b-input-group>
            <b-form-textarea v-model="descricao" />
          </b-input-group>
        </b-col>

        <b-col
          md="12"
          class="mt-1"
        >
          <b-form-group>
            <b-form-checkbox
              id="checkbox-2"
              v-model="informacaoPrivada"
              name="checkbox-2"
            >
              Informação Privada
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BAlert, BOverlay, BModal, BInputGroup, BFormTextarea, BFormCheckbox, BFormGroup } from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import vSelect from 'vue-select'

import { mapState } from 'vuex'
import axios from '@/../axios-auth'

export default {
  components: {
    BInputGroup,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BAlert,
    AppTimeline,
    AppTimelineItem,
    BOverlay,
    BModal,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
  },
  data() {
    return {
      motivoEventos: [],
      motivoSelected: null,
      descricao: '',
      informacaoPrivada: false,
    }
  },
  computed: {
    ...mapState({
      historico: state => state.os.historicoOs,
      loadingHistorico: state => state.os.loadingHistorico,
    }),
  },
  watch: {
  },
  async created() {
    this.getMotivosEventos()
  },
  methods: {
    varianteEvento(setor) {
      const colors = {
        AUDITOR: 'warning',
        ATENDENTE: 'primary',
        DIRECIONADOR: 'info',
        EXTERNO: 'danger',
        FINANCEIRO: 'success',
        ORCAMENTO: 'secondary',
      }
      return colors[setor] || 'secondary'
    },

    openModalNewHistorico() {
      this.$refs.modalHistorico.show()
    },

    getMotivosEventos() {
      axios.get('api/motivo_evento/').then(res => {
        const options = res.data.dados.filter(item => item.status === 1 && item.nome === 'RECLAMAÇÃO')

        if (options[0]) [this.motivoSelected] = options
        this.motivoEventos = options
      })
    },

    newHistorico() {
      this.load = true
      const body = {
        ordem_de_servico_id: parseInt(this.$route.params.id),
        motivo_evento_id: this.motivoSelected.id,
        descricao: this.descricao.toUpperCase(),
      }
      this.informacaoPrivada ? body.privada = this.informacaoPrivada : null
      axios
        .post('api/evento/store', body)
        .then(async () => {
          await this.$store.dispatch('os/getHistoricoOs', { id: this.$route.params.id })
          this.clearFormModalHistorico()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'HISTÓRICO CADASTRADO COM SUCESSO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(async error => {
          await this.$store.dispatch('os/getHistoricoOs', { id: this.$route.params.id })

          this.clearFormModalHistorico()
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: `Ocorreu um erro durante o cadastro: ${error.response.data.mensagem} `,
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },

    clearFormModalHistorico() {
      this.descricao = ''
      this.informacaoPrivada = false
      this.eventos.selected = []
    },
  },
}
</script>
