<template>
  <b-card v-if="os">
    <b-row>
      <b-col
        cols="21"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <b-avatar
            size="50px"
            rounded
          />

          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ os.cliente.nome }}
              </h4>
              <span class="card-text">{{ cpfCNPJ(os.cliente.cpf_cnpj) }}</span>
              <br>

              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ os.cliente.telefone ? phoneLabel(os.cliente.telefone) : '' }}</span>
              <br>

              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ os.cliente.email }}</span>
              <br>

              <div v-if="os.cliente.cidade">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ os.cliente.cidade.nome }}</span>
                <br>
              </div>

              <b-badge
                class="mt-1"
                :variant="resolveBadgeVarianteStatusOs(os.status_os.nome)"
              >
                {{ os.status_os.nome }}
              </b-badge>
            </div>

            <!-- <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
              >
                Gerar OS
              </b-button>
            </div> -->

            <div class="d-flex justify-content-start">
              <b-button
                v-if="os.status_os.nome === 'EM REPARO'"
                class="mt-1"
                variant="info"
                size="sm"
              >
                Confirmar Reparo concluído
              </b-button>
              <b-button
                v-if="os.status_os.nome === 'REPARO CONCLUÍDO'"
                class="mt-1"
                variant="success"
                size="sm"
              >
                Confirmar Reparo Entregue
              </b-button>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center mt-2">
          <div
            v-b-tooltip.hover.top="'Quantidade de dias em aberto'"
            class="d-flex align-items-center mr-2"
          >
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ diasEmAberto(os.data_abertura, os.data_fechamento) }}
              </h5>
              <small>QDA</small>
            </div>
          </div>

          <div
            v-b-tooltip.hover.top="'Reagendamento de Visita'"
            class="d-flex align-items-center mr-1"
          >
            <b-avatar
              variant="light-warning"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ os.total_reagendamento_visita }}
              </h5>
              <small>RV</small>
            </div>
          </div>

          <div
            v-b-tooltip.hover.top="'Reagendamento de Reparo'"
            class="d-flex align-items-center"
          >
            <b-avatar
              variant="light-info"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ os.total_reagendamento_reparo }}
              </h5>
              <small>RR</small>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col
        cols="21"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <table class="mt-2 mt-xl-0 w-120 mt-1">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ServerIcon"
                class="mr-75"
              />
              <span class="font-weight-bold mr-1">Sinistro</span>
            </th>
            <td>
              #{{ os.sinistro }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ApertureIcon"
                class="mr-75"
              />
              <span class="font-weight-bold mr-1">Atendimento</span>
            </th>
            <td>
              {{ os.tipo_atendimento }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Abertura</span>
            </th>
            <td>
              {{ os.data_abertura ? formatTimezone(os.data_abertura) : '' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Fechamento</span>
            </th>
            <td>
              {{ os.data_fechamento ? formatTimezone(os.data_fechamento) : '' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CodesandboxIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Produto</span>
            </th>
            <td class="pb-50">
              {{ os.produto.nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Valor</span>
            </th>
            <td class="pb-50">
              {{ moedaBR(os.produto.valor_custo) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CodesandboxIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Marca</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ os.produto.marca.nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CommandIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Linha</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ os.produto.linha.nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="AwardIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Seguradora</span>
            </th>
            <td class="pb-50">
              {{ os.produto.seguradora ? os.produto.seguradora.nome : '' }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BAvatar, BBadge, BButton } from 'bootstrap-vue'

import { mapState } from 'vuex'

export default {
  components: {
    BRow, BCol, BCard, BAvatar, BBadge, BButton,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      os: state => state.os.os,
    }),
  },

  methods: {
    diasEmAberto(dataAbertura, dataFechamento) {
      if (!dataFechamento) {
        dataFechamento = new Date()
      }
      dataAbertura = new Date(dataAbertura)
      dataFechamento = new Date(dataFechamento)

      const diferenca = dataFechamento.getTime() - dataAbertura.getTime()
      const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))
      return dias
    },

    moedaBR(valor) {
      const numero = parseFloat(valor)
      const moeda = numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return moeda
    },
  },
}
</script>
