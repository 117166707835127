<template>
  <div>
    <b-row>
      <b-col
        md="3"
        sm="12"
      >
        <b-row>
          <b-col>
            <OsGeneralInfo />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <OsTimeline />
          </b-col>
        </b-row>
      </b-col>

      <b-col
        md="9"
        sm="12"
      >
        <MainContent />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import OsTimeline from './components/OsTimeline.vue'
import OsGeneralInfo from './components/OsGeneralInfo.vue'
import MainContent from './components/main/MainContent.vue'

export default {
  components: {
    BRow,
    BCol,
    OsGeneralInfo,
    OsTimeline,
    MainContent,
  },
  props: {},
  async created() {
    await this.$store.dispatch('os/getHistoricoOs', { id: this.$route.params.id })
    await this.$store.dispatch('os/getOrcamentosOs', { id: this.$route.params.id })
  },
}
</script>
