<template>
  <b-overlay :show="false">
    <b-card>
      <b-row>
        <b-col
          md="2"
        >
          <b-form-group
            label="ID"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="info.id"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="Sinistro"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="`#${info.sinistro}`"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="3"
          xl="3"
        >
          <!-- readonly input -->
          <b-form-group
            label="Data Abertura"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="info.data_abertura ? formatTimezone(info.data_abertura) : ''"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="5"
          xl="5"
        >
          <!-- readonly input -->
          <b-form-group
            label="Tipo de Atendimento"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.tipo_atendimento"
              readonly
            />
            <b-form-select
              v-if="editar"
              v-model="info.tipo_atendimento"
              :options="optionsAtendimento"
            />
          </b-form-group>
        </b-col>

        <b-col class="col-12">
          <hr>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Cliente"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.cliente.nome"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="CPF/CNPJ"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="info.cliente.cpf_cnpj"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="3"
          xl="3"
        >
          <!-- readonly input -->
          <b-form-group
            label="E-mail"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.cliente.email"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="3"
          xl="3"
        >
          <!-- readonly input -->
          <b-form-group
            label="Telefone"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.cliente.telefone"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="5"
          xl="5"
        >
          <!-- readonly input -->
          <b-form-group
            label="Endereco"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.cliente.endereco"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="CEP"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.cliente.cep"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="Bairro"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.cliente.bairro"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="Cidade"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="info.cliente.cidade ? info.cliente.cidade.nome : ''"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
          xl="1"
        >
          <!-- readonly input -->
          <b-form-group
            label="UF"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.cliente.uf"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col class="col-12">
          <hr>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Produto"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="info.produto ? info.produto.nome : ''"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Marca"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.marca.nome"
              readonly
            />
          </b-form-group>

        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Revenda"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.revenda.nome"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Linha"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.linha.nome"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Tipo de garantia"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.tipo_garantia"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Seguradora"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.seguradora.nome"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Valor do produto"
            label-for="readOnlyInput"
          >
            <money
              id="readOnlyInput"
              v-model="info.produto.valor_bruto"
              v-bind="money"
              class="form-control"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Modelo"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.modelo"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Nº Série"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.numero_serie"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Certificado"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.certificado"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Nº NF"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.numero_nf"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Imei"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.produto.imei"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col class="col-12">
          <hr>
        </b-col>

        <b-col
          md="12"
          xl="12"
        >
          <!-- readonly input -->
          <b-form-group
            label="Relato do Cliente"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.relato_cliente"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col
          md="12"
          xl="12"
        >
          <!-- readonly input -->
          <b-form-group
            label="Observação"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              v-model="info.observacao"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

import { Money } from 'v-money'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BFormGroup,
    BFormInput,
    Money,
  },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },

  computed: {
    ...mapState({
      info: state => state.os.os,
    }),
  },
}
</script>
