<template>
  <b-tabs>
    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Orcamento</span>
      </template>

      <b-row class="mb-1">
        <b-col md="2">
          <label><strong>Sinistro</strong></label>
          <b-form-input
            :value="orcamentoSelected.sinistro"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>Data Abertura</strong></label>
          <b-form-input
            :value="orcamentoSelected.data_abertura ? formatTimezone(orcamentoSelected.data_abertura, true) : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>Status</strong></label>
          <b-form-input
            :value="orcamentoSelected.status"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>Valor Total Orcamento</strong></label>
          <money
            :value="orcamentoSelected.valor_total_orcamento"
            v-bind="money"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>Valor Aprovado no Sistema</strong></label>
          <money
            :value="orcamentoSelected.valor_total_aprovado"
            v-bind="money"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>Tipo Orçamento</strong></label>
          <b-form-input
            :value="orcamentoSelected.tipo_orcamento"
            disabled
          />
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col>
          <label><strong>Data Criação</strong></label>
          <b-form-input
            :value="orcamentoSelected.data_criacao ? formatTimezone(orcamentoSelected.data_criacao, true) : ''"
            disabled
          />
        </b-col>

        <b-col>
          <label><strong>Data Auditação</strong></label>
          <b-form-input
            :value="orcamentoSelected.data_auditacao ? formatTimezone(orcamentoSelected.data_auditacao, true) : ''"
            disabled
          />
        </b-col>

        <b-col>
          <label><strong>Data Envio Orçamento</strong></label>
          <b-form-input
            :value="orcamentoSelected.data_envio_orcamento ? formatTimezone(orcamentoSelected.data_envio_orcamento, true) : ''"
            disabled
          />
        </b-col>

        <b-col>
          <label><strong>Status Retorno</strong></label>
          <b-form-input
            :value="orcamentoSelected.status_retorno"
            disabled
          />
        </b-col>

        <b-col>
          <label><strong>Valor Total Seguradora</strong></label>
          <money
            :value="orcamentoSelected.valor_total_seguradora"
            v-bind="money"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col>
          <label><strong>Retorno Orçamento</strong></label>
          <b-form-input
            v-if="orcamentoSelected.status !== 'ENVIADO PARA SEGURADORA'"
            :value="orcamentoSelected.data_retorno_orcamento ? formatTimezone(orcamentoSelected.data_retorno_orcamento, true) : ''"
            disabled
          />
        </b-col>
      </b-row>
      <hr class="mt-1">
      <b-row
        v-for="(dados, index) in orcamentoSelected.servicos"
        :key="index"
        class="mb-1 match-height"
      >
        <b-col md="2">
          <label for=""><strong>Tipo Servico</strong></label>
          <b-form-input
            :value="dados.tipo_servico"
            disabled
          />
        </b-col>

        <b-col md="3">
          <label for=""><strong>Descrição</strong></label>
          <b-form-input
            :value="dados.descricao"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>Observação</strong></label>
          <b-form-input
            :value="dados.observacao"
            disabled
          />
        </b-col>

        <b-col md="1">
          <label for=""><strong>Quant.</strong></label>
          <b-form-input
            :value="dados.quantidade"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>Valor Solicitado</strong></label>
          <div class="d-flex">
            <money
              :value="dados.valor_solicitado"
              v-bind="money"
              class="form-control"
              readonly
            />
          </div>
        </b-col>

        <b-col md="2">
          <label for=""><strong>Valor Aprovado</strong></label>
          <b-row>
            <b-col>
              <money
                :value="dados.valor_aprovado"
                v-bind="money"
                class="form-control"
                readonly
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Prestador de Serviço</span>
      </template>

      <b-row class="mb-1">
        <b-col md="2">
          <label><strong>NOME</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.nome : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>CPF_CNPJ</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.cpf_cnpj : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>CHAVE PIX</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.chave_pix : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>BANCO</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.banco : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>AGENCIA</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.agencia : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>CONTA</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.conta : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>TELEFONE</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.telefone : ''"
            disabled
          />
        </b-col>

        <b-col md="2">
          <label for=""><strong>EMAIL</strong></label>
          <b-form-input
            :value="orcamentoSelected.prestador_servico ? orcamentoSelected.prestador_servico.email : ''"
            disabled
          />
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BRow, BCol, BTabs, BTab, BFormInput } from 'bootstrap-vue'
import { mapState } from 'vuex'
import { Money } from 'v-money'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormInput,
    Money,
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  computed: {
    ...mapState({
      orcamentoSelected: state => state.os.orcamentoSelected,
    }),
  },
}
</script>
