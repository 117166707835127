<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <OsPage />
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex'
import OsPage from '../../../components/os-view/OsPage.vue'

export default {
  components: {
    OsPage,
    BOverlay,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      showLoading: state => state.os.showLoading,
    }),
  },
  async created() {
    this.$store.dispatch('os/getOsData', { id: this.$route.params.id })
  },
}
</script>
