<template>
  <b-card>
    <b-tabs>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="AlignCenterIcon" />
          <span>Histórico</span>
        </template>
        <Historico />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="AlignCenterIcon" />
          <span>Informações</span>
        </template>

        <Info />
      </b-tab>

      <b-tab
        lazy
      >
        <template #title>
          <feather-icon icon="AlignCenterIcon" />
          <span>Orçamentos</span>
        </template>

        <Orcamento />
      </b-tab>

      <b-tab
        lazy
        active
      >
        <template #title>
          <feather-icon icon="ArchiveIcon" />
          <span>Arquivos</span>
        </template>

        <Arquivos />
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import Historico from './tabs/Historico.vue'
import Info from './tabs/Info.vue'
import Orcamento from './tabs/Orcamentos.vue'
import Arquivos from './tabs/Arquivos.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    Historico,
    Info,
    Orcamento,
    Arquivos,
  },
}
</script>
